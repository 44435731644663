<template>
  <b-form-group
    label="Satış Föyü No"
    label-for="order_number"
  >
    <b-form-input
      id="order_number"
      placeholder="Satış Föyü No"
      :value="dataItem.order_number"
      disabled
    />
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'OrderNumber',
  components: { BFormInput, BFormGroup },
  computed: {
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
