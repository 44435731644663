<template>
  <b-form-group
    label="E-Posta"
    label-for="email"
  >
    <validation-provider
      #default="{ errors }"
      name="E-Posta"
      rules="required|email"
    >
      <b-form-input
        id="email"
        placeholder="E-Posta"
        :value="dataItem.email"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'EmailAddress',
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
