<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col
        cols="12"
      >
        <order-number />
      </b-col>
      <b-col
        cols="12"
      >
        <email-address />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <birthday-date />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <delivery-date />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <license-plate />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <location-select />
      </b-col>
      <b-col
        cols="12"
      >
        <hr>
        <h4 class="mb-1">
          Teşekkür Mektubu
        </h4>
        <mail-model-select />
        <delivery-photo />
        <b-alert
          v-if="dataItem.modelType && dataItem.photo"
          show
        >
          <div class="alert-body text-center">
            <FeatherIcon icon="InfoIcon" />
            Teşekkür mektubu otomatik gönderilmektedir.
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12">
        <save-button
          :save-action="saveData"
          :is-disabled="submitStatus"
        />
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BAlert,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import OrderNumber from '@/views/Sales/Sale_delivery/Pending/elements/OrderNumber.vue'
import EmailAddress from '@/views/Sales/Sale_delivery/Pending/elements/EmailAddress.vue'
import BirthdayDate from '@/views/Sales/Sale_delivery/Pending/elements/BirthdayDate.vue'
import DeliveryDate from '@/views/Sales/Sale_delivery/Pending/elements/DeliveryDate.vue'
import LicensePlate from '@/views/Sales/Sale_delivery/Pending/elements/LicensePlate.vue'
import LocationSelect from '@/views/Sales/Sale_delivery/Pending/elements/LocationSelect.vue'
import MailModelSelect from '@/views/Sales/Sale_delivery/Pending/elements/MailModelSelect.vue'
import DeliveryPhoto from '@/views/Sales/Sale_delivery/Pending/elements/DeliveryPhoto.vue'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    BAlert,
    ValidationObserver,
    SaveButton,
    OrderNumber,
    EmailAddress,
    BirthdayDate,
    DeliveryDate,
    LicensePlate,
    LocationSelect,
    MailModelSelect,
    DeliveryPhoto,
  },
  data() {
    return {
      submitStatus: false,
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.id_com_sale_orderform = this.$route.params.id
          this.$store.dispatch('saleDelivery/saleDeliverySave', this.dataItem)
        }
      })
    },
  },
}
</script>
