<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <customer-card />
        <car-card />
      </b-col>
      <b-col
        v-if="dataItem.id_com_customer"
        cols="12"
        md="8"
      >
        <kvkk-inline
          v-if="dataItem.kvkk !== '1'"
          :refresh-data="getData"
          :customer-id="dataItem.id_com_customer"
        />
        <b-card>
          <b-alert
            class="text-center"
            :variant="dataItem.kvkk === '1' ? 'success' : 'danger'"
            show
          >
            <div class="alert-body">
              <span v-if="dataItem.kvkk === '1'">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50 mt-25"
                />
                Müşterinin KVKK onayı var.</span>
              <span v-else>
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50 mt-25"
                />
                Müşterinin KVKK onayı yok.</span>
            </div>
          </b-alert>
          <delivery-form />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import CustomerCard from '@/views/Sales/Sale_delivery/Pending/Add/CustomerCard.vue'
import CarCard from '@/views/Sales/Sale_delivery/Pending/Add/CarCard.vue'
// import MailCard from '@/views/Sales/Sale_delivery/Pending/Add/MailCard.vue'
import DeliveryForm from '@/views/Sales/Sale_delivery/Pending/Add/DeliveryForm.vue'
import KvkkInline from '@/layouts/components/common/KvkkInline.vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    CustomerCard,
    CarCard,
    // MailCard,
    DeliveryForm,
    KvkkInline,
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
    saveStatus() {
      return this.$store.getters['saleDelivery/getSaveStatus']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getData()
    this.getLocations()
  },
  methods: {
    getData() {
      this.$store.dispatch('saleDelivery/getData', this.$route.params.id)
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        where: {
          // 'com_location.ip_address !=': null,
        },
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(resoponse => {
        if (resoponse.isConfirmed) {
          this.$router.push('/sale_deliveries')
        }
      })
    },
  },
}
</script>
