<template>
  <b-card title="Müşteri Bilgisi">
    <b-list-group>
      <b-list-group-item v-if="dataItem.company">
        <div class="text-primary font-weight-bold">
          Firma Ünvanı
        </div>
        <div>{{ dataItem.company }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.customer">
        <div class="text-primary font-weight-bold">
          Adı Soyadı
        </div>
        <div>{{ dataItem.customer }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.tax_office">
        <div class="text-primary font-weight-bold">
          Vergi Dairesi
        </div>
        <div>{{ dataItem.tax_office }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.tax_number">
        <div class="text-primary font-weight-bold">
          Vergi No
        </div>
        <div>{{ dataItem.tax_number }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.phone">
        <div class="text-primary font-weight-bold">
          Cep Telefonu
        </div>
        <div>{{ dataItem.phone }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.email">
        <div class="text-primary font-weight-bold">
          E-Posta
        </div>
        <div>{{ dataItem.email }}</div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'CustomerCard',
  components: {
    BCard, BListGroup, BListGroupItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
