<template>
  <b-row>
    <b-col
      v-if="kvkkSms.result !== true"
      cols="12"
    >
      <b-form-group>
        <b-input-group>
          <b-input-group-prepend is-text>
            TR (+9)
          </b-input-group-prepend>
          <cleave
            id="phone"
            v-model="customer.phone"
            class="form-control"
            :raw="false"
            :options="options.phone"
            placeholder="Telefon"
            readonly
          />
          <b-input-group-append>
            <b-button
              variant="outline-primary"
              @click="kvkkSmsSend"
            >
              Onay Kodu Gönder
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      v-if="kvkkSms.sms_status && kvkkSms.result !== true"
      cols="12"
    >
      <b-form-group
        label="Onay Kodu"
        label-for="code"
      >
        <b-input-group>
          <b-form-input
            id="code"
            v-model="kvkkSms.code"
            placeholder="Onay Kodu"
            :disabled="kvkkSms.countDown === 0"
          />
          <b-input-group-append>
            <b-button
              variant="outline-success"
              :disabled="kvkkSms.countDown === 0"
              @click="kvkkConfirm"
            >
              Onayla
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      v-if="kvkkSms.sms_status && kvkkSms.result !== true"
      cols="12"
    >
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body">
          Kalan Süre: {{ kvkkSms.countDown }}
        </div>
      </b-alert>
      <b-alert
        v-if="kvkkSms.error"
        show
        variant="danger"
      >
        <div class="alert-body">
          {{ kvkkSms.error }}
        </div>
      </b-alert>
    </b-col>
    <b-col
      v-if="kvkkSms.result"
      cols="12"
    >
      <b-alert
        show
        variant="success"
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="CheckIcon" />
          KVKK Sms onayı tamamlandı.
        </div>
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
  name: 'KvkkForm',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BAlert,
    Cleave,
  },
  props: {
    customerId: {
      // eslint-disable-next-line vue/require-prop-type-constructor,no-bitwise
      type: String | Number,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    kvkkSms() {
      return this.$store.getters['customers/getKvkkSms']
    },
  },
  watch: {
    kvkkSms: {
      deep: true,
      handler(val) {
        if (val.sms_status === true && val.countDown === 180) {
          this.countDownTimer()
        }
        if (val.result) {
          this.refreshData()
        }
      },
    },
  },
  created() {
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.customerId)
    },
    kvkkSmsSend() {
      this.$store.dispatch('customers/kvkkSmsSend', {
        phone: this.customer.phone,
        id_com_customer: this.customer.id,
      })
    },
    kvkkConfirm() {
      this.$store.dispatch('customers/kvkkConfirm', {
        code: this.kvkkSms.code,
        id_com_customer: this.customer.id,
      })
    },
    countDownTimer() {
      if (this.kvkkSms.countDown > 0) {
        setTimeout(() => {
          this.kvkkSms.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
  },
}
</script>
