<template>
  <b-form-group
    label="Doğum Tarihi"
    label-for="birthday"
  >
    <validation-provider
      #default="{ errors }"
      name="Doğum Tarihi"
      rules="required"
    >
      <cleave
        id="birthday"
        v-model="dataItem.birthday"
        class="form-control"
        :raw="false"
        :options="options.dateMasked"
        placeholder="GG.AA.YYYY"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'BirthdayDate',
  components: {
    ValidationProvider,
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      required,
      options: {
        dateMasked: {
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
