<template>
  <b-form-group
    v-if="models[Number(dataItem.id_com_brand)].length > 0"
    label="Teşekkür Mektubu Araç Modeli"
    label-for="model"
  >
    <validation-provider
      #default="{ errors }"
      name="Teşekkür Mektubu Araç Modeli"
      rules="required"
    >
      <v-select
        id="model"
        v-model="dataItem.modelType"
        :options="models[Number(dataItem.id_com_brand)]"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'EmailAddress',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  data() {
    return {
      required,
      models: {
        1: [],
      },
    }
  },
  computed: {
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
