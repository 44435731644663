<template>
  <b-form-group
    label="Teslimat Yeri"
    label-for="id_com_location"
  >
    <validation-provider
      #default="{ errors }"
      name="Teslimat Yeri"
      rules="required"
    >
      <v-select
        id="id_com_location"
        v-model="dataItem.id_com_location"
        :options="locations"
        label="title"
        :reduce="item => item.id"
        placeholder="Teslimat Yeri"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'EmailAddress',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
