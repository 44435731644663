<template>
  <b-card title="Araç Bilgisi">
    <b-list-group>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Araç Modeli
        </div>
        <div>{{ dataItem.brand }} {{ dataItem.model }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.myear">
        <div class="text-primary font-weight-bold">
          Model Yılı
        </div>
        <div>{{ dataItem.myear }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.chasis">
        <div class="text-primary font-weight-bold">
          Şase No
        </div>
        <div>{{ dataItem.chasis }}</div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'CarCard',
  components: {
    BCard, BListGroup, BListGroupItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
