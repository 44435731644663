<template>
  <b-form-group
    label="Teslimat Fotoğrafı"
    label-for="photo"
  >
    <validation-provider
      #default="{ errors }"
      name="Teslimat Fotoğrafı"
      rules="required"
    >
      <b-form-file
        id="photo"
        v-model="dataItem.photo"
        placeholder="Teslimat Fotoğrafı"
        drop-placeholder="Fotoğrafı buraya sürükleyin"
        browse-text="Fotoğraf Seçin"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormFile } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'BirthdayDate',
  components: {
    ValidationProvider,
    BFormFile,
    BFormGroup,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
